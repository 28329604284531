import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import {CloudUpload} from "@mui/icons-material";
import {ExcelIcon, PDFIcon, PowerPointIcon, WordIcon} from "../../components/Icons";
import {GenerationType, useRequestState} from "../../hooks/RequestState";
import {useNavigate} from "react-router-dom";
import FileList from "../../components/FileList";
import {useAuth} from "react-oidc-context";
import {PromptFlowRepository} from "../../repositories/PromptFlowRepository";
import {PromptFlow} from "../../models/promptflow/PromptFlow";
import {AuthHelpers, UserRole} from "../../Utils/AuthHelpers";

const UploadScreen = () => {
    const auth = useAuth();
    const flowRepository = new PromptFlowRepository(useAuth());
    const {requestState, updateRequestState} = useRequestState();
    const [flowList, setFlowList] = useState<PromptFlow[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        LoadFlows().catch();
    }, []);

    const LoadFlows = async () => {
        let flows = AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin) 
            ? await flowRepository.allFlows() 
            : await flowRepository.activeFlows();
        if (flows !== undefined) {
            setFlowList(flows);
            if (flows.length === 1) {

                updateRequestState({
                    ...requestState,
                    promptFlowId: +flows[0].id,
                });
            }
        }
    }
    
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const newFiles = Array.from(event.dataTransfer.files);
        updateRequestState({
            ...requestState,
            files: [...requestState.files, ...newFiles]
        });
    };

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedFiles = event.target.files;
        if (!selectedFiles) {
            return;
        }
        const newFiles = Array.from(selectedFiles);
        updateRequestState({ 
            ...requestState, 
            files: [...requestState.files, ...newFiles] 
        });
    };

    function startGenerateImprovementSuggestion() {
        updateRequestState({
            ...requestState,
            generationType: GenerationType.Improvement
        });
        navigate("/processing");
    }

    const startGenerateProposal = () => {
        updateRequestState({
            ...requestState,
            generationType: GenerationType.Proposal
        });
        navigate("/processing");
    }

    const startPromptFlow = () => {
        updateRequestState({
            ...requestState,
            generationType: GenerationType.FlowStep
        });
        navigate("/processing");
    };
    
    const selectPrompt = (e: any) => {
        updateRequestState({
            ...requestState,
            promptFlowId: +e.target.value
        });
    };


    return (
        <Paper
            elevation={3}
            sx={{
                p: 4,
                maxWidth: 500,
                mx: "auto",
                mt: 4,
                backgroundColor: "#ffffff",
                borderRadius: 2,
            }}
        >
            <Box sx={{textAlign: "center", mb: 2}}>
                <img
                    src="/Logo.svg"
                    alt="Rake Voorstellen Logo"
                    style={{width: 200, marginBottom: 16}}
                />
            </Box>
            <Box
                sx={{
                    border: "2px dashed #5743D9",
                    borderRadius: 2,
                    p: 3,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#f0f0f0",
                    },
                }}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
                onClick={() => document.getElementById("fileInput")?.click()}
            >
                <input
                    id="fileInput"
                    type="file"
                    multiple
                    onChange={handleFileInput}
                    style={{display: "none"}}
                />
                <CloudUpload sx={{fontSize: 48, color: "#5743D9", mb: 2}}/>
                <Typography variant="body1" gutterBottom>
                    Op welke informatie wil je je voorstel baseren? Sleep de bestanden hierin.
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Ondersteunde formaten:
                </Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{mb: 2}}
                >
                    <WordIcon
                        sx={{fontSize: 40, color: "#2B579A", mx: 1}}
                        titleAccess="Word"
                    />
                    <ExcelIcon
                        sx={{fontSize: 40, color: "#217346", mx: 1}}
                        titleAccess="Excel"
                    />
                    <PowerPointIcon
                        sx={{fontSize: 40, color: "#D24726", mx: 1}}
                        titleAccess="PowerPoint"
                    />
                    <PDFIcon
                        sx={{fontSize: 40, color: "#F40F02", mx: 1}}
                        titleAccess="PDF"
                    />
                </Box>
                <Button variant="contained" component="span" sx={{mt: 2}}>
                    Kies bestanden
                </Button>
            </Box>
            <FileList files={requestState.files} allowRemove={true} />
            {requestState.files.length > 0 && (
                <>
                    {
                        flowList.length == 1
                            ? <>
                                <Box sx={{display: 'grid', gridAutoColumns: '1fr', gap: 1}}>
                                    {/*<Button*/}
                                    {/*    variant="contained"*/}
                                    {/*    sx={{gridRow: '1', gridColumn: 'span 2'}}*/}
                                    {/*    onClick={startGenerateImprovementSuggestion}*/}
                                    {/*>*/}
                                    {/*    Ontvang feedback*/}
                                    {/*</Button>*/}
                                    <Button
                                        variant="contained"
                                        sx={{gridRow: '1', gridColumn: 'span 2'}}
                                        onClick={startPromptFlow}
                                    >
                                        Maak Voorstel
                                    </Button>
                                </Box>
                            </>
                            : <>
                                <Box sx={{mt: 2, display: 'grid', gridAutoColumns: '1fr', gap: 1}}>
                                    <FormControl sx={{gridRow: '1', gridColumn: 'span 2'}}>
                                        <InputLabel id="prompt-select-label">Prompt:</InputLabel>
                                        <Select
                                            labelId="prompt-select-label"
                                            value={requestState.promptFlowId}
                                            label="Prompt"
                                            onChange={selectPrompt}>
                                            {flowList.map((item) => (
                                                <MenuItem key={item.id}
                                                          value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        sx={{gridRow: '1'}}
                                        onClick={startPromptFlow}
                                    >
                                        Maak Voorstel
                                    </Button>
                                </Box>
                            </>
                    }
                </>
            )}
        </Paper>
    );
};

export default UploadScreen;